const navbarInit = function navbarInit() {
  const navbar = document.querySelector('[data-navbar-on-scroll]');
  let navbarTogglerIcon = document.querySelector('.navbar-dark .navbar-toggler-icon');
  const logo = document.querySelector('.navbar-brand img');
  const defaultLogoSrc = '/assets/img/logo/logo.svg';
  const whiteLogoSrc = '/assets/img/logo/logo-white.svg';
  const scrollThreshold = 120; // Pixels threshold before navbar transition begins

  let isMenuOpen = false; // Flag to track the state of the mobile menu

  // Set initial logo to white
  logo.src = whiteLogoSrc;

  const forceRepaint = (el) => {
    const clonedEl = el.cloneNode(true);
    el.replaceWith(clonedEl);
    return clonedEl;
  };

  if (navbar) {
    const handleScroll = function handleScroll() {
      const scrollTop = window.pageYOffset;

      if (scrollTop > scrollThreshold) { // Apply changes only after scrolling past the threshold
        navbar.classList.add('scrolled');
        if (!isMenuOpen) {
          navbarTogglerIcon.style.backgroundImage = `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30' height='30'%3e%3cpath stroke='rgba(15, 36, 67, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")`;
          logo.src = defaultLogoSrc;
        }
      } else {
        navbar.classList.remove('scrolled');
        if (!isMenuOpen) {
          navbarTogglerIcon.style.backgroundImage = `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30' height='30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")`;
          logo.src = whiteLogoSrc;
        }
      }
      const newNavbarTogglerIcon = forceRepaint(navbarTogglerIcon); // Force repaint to ensure smooth transition
      navbarTogglerIcon = newNavbarTogglerIcon;
    };

    document.addEventListener('scroll', handleScroll);

    // Top navigation background toggle on mobile
    navbar.addEventListener('show.bs.collapse', function (e) {
      isMenuOpen = true;
      e.currentTarget.classList.add('bg-dark');
      // Ensure the toggler icon turns white on mobile menu expansion
      navbarTogglerIcon.style.backgroundImage = `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30' height='30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")`;
      logo.src = whiteLogoSrc; // Keep the white logo when mobile menu is open
      const newNavbarTogglerIcon = forceRepaint(navbarTogglerIcon); // Force repaint to ensure smooth transition
      navbarTogglerIcon = newNavbarTogglerIcon;
      navbar.style.boxShadow = 'none'; // Remove box shadow when mobile menu is open

      // Add class to ensure links are white
      const navLinks = document.querySelectorAll('.navbar-collapse .nav-link');
      navLinks.forEach(link => link.classList.add('text-white'));
    });

    navbar.addEventListener('hide.bs.collapse', function (e) {
      isMenuOpen = false;
      e.currentTarget.classList.remove('bg-dark');
      // Reset the toggler icon color based on scroll position when mobile menu collapses
      handleScroll();
      // Re-apply box shadow
      navbar.style.boxShadow = '';

      // Remove the class to reset link colors based on original styles
      const navLinks = document.querySelectorAll('.navbar-collapse .nav-link');
      navLinks.forEach(link => link.classList.remove('text-white'));
    });
  }
};

export default navbarInit;
